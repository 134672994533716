<script>
import {  toRaw } from 'vue';

import Swal from "sweetalert2";
import "@fullcalendar/core/vdom";
import { SimpleBar } from "simplebar-vue3";
import { CalendarIcon } from "@zhuowenli/vue-feather-icons";
const moment = require('moment')
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
//import esLocale from "@fullcalendar/core/locales/es";
import itLocale from "@fullcalendar/core/locales/it";

import { mapActions} from "vuex";

import serverConfig from '@/helpers/config.js'
import axios from 'axios';

import FullCalendar from "@fullcalendar/vue3";

import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import appConfig from "../../../app.config";

import {  categories, customerCategories, eventCatByVehicle } from "@/views/calendar/utils";


export default {
  name:'calendarWidget',
  props:[
    "type",
    "resourceID",
    "module",
  ],
  setup() {
    let tk = 'Bearer '+localStorage.getItem('tk')
    let INITIAL_EVENTS = []
    return { v$: useVuelidate(),tk, INITIAL_EVENTS };
  },
  validations: {
    event: {
      title: {
        required: helpers.withMessage("Nome evento richiesto", required),
      },
      category: {
        required: helpers.withMessage("Catetoria obbligatoria", required),
      },
      startDate: {
        required: helpers.withMessage("Data inizio obbligatoria", required),
      },
      endDate: {
        required: helpers.withMessage("Data fine obbligatoria", required),
      },
    },
    editevent: {
      title: {
        required: helpers.withMessage("Nome evento richiesto", required),
      },
      category: {
        required: helpers.withMessage("Catetoria obbligatoria", required),
      },
      startDate: {
        required: helpers.withMessage("Data inizio obbligatoria", required),
      },
      endDate: {
        required: helpers.withMessage("Data fine obbligatoria", required),
      },
    },
    edit: {
      title: {
        required: helpers.withMessage("Nome evento richiesto", required),
      },
      category: {
        required: helpers.withMessage("Catetoria obbligatoria", required),
      },
      start: {
        required: helpers.withMessage("Data inizio obbligatoria", required),
      },
      end: {
        required: helpers.withMessage("Data fine obbligatoria", required),
      },
    },
  },
  page: {
    title: "Calendario",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      config:serverConfig,
      filterEventBy:"",
      events:[],
      eventsOptionsList:[],
      calendarOptions: {
        timeZone: "local",
        initialEvents:[],
        droppable: true,
        drop: this.dropEvent,
        navLinks: true,
        datesSet: this.handleMonthChange,
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        locale:itLocale,
        themeSystem: "bootstrap",
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },
        windowResize: () => {
          this.getInitialView();
        },
        initialView: this.getInitialView(),
        eventOverlap: function(stillEvent, movingEvent) {
          console.log(stillEvent)
          console.log(movingEvent)
          return stillEvent.allDay && movingEvent.allDay;
        },
        events: [],
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        dateClick: this.dateClicked,
        eventClick: this.editEvent,
        eventsSet: this.handleEvents,
      },
      currentEvents: [],
      showModal: false,
      eventModal: false,
      eventCatV: eventCatByVehicle,
      eventCatUsr: categories,
      eventCustomerCat : customerCategories,
      categories: [],
      submitted: false,
      submit: false,
      newEventData: {},
      edit: {},
      deleteId: {},
      event: {
        title: "",
        category: "",
        location:"",
        address:"",
        lat:"",
        long:"",
        allDay:false,
        startDate:null,
        endDate:null,
        start:null,
        end:null,
        description:"",
        uid_sender:this.$store.state.auth.currentUser.uid,
        uid_recipient:this.$store.state.auth.currentUser.uid,
      },
      editevent: {
        editTitle: "",
        editcategory: "",
        title: "",
        category: "",
        location:"",
        address:"",
        lat:"",
        long:"",
        allDay:false,
        startDate:null,
        endDate:null,
        start:null,
        end:null,
        description:"",
        uid_sender:this.$store.state.auth.currentUser.uid,
        uid_recipient:this.$store.state.auth.currentUser.uid,
      },
      apiCalendar:{},
      apiCalHandleChange:{},
      currentRange:{},
      currentData:{},
      calendarTasks:{},
      changedDataState:false,
      calendarTest:[],
      editableData:true,
    };
  },
  components: {
    FullCalendar,
    SimpleBar,
    CalendarIcon
  },
  beforeMount(){
    axios.interceptors.response.use(
        (response) => {
            
            return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
                Swal.fire({
                  title:"Risorsa non accessibile",
                  text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                this.$router.push('/')   
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
    );
    axios.get(`${serverConfig.EP}users/acl`, 
        { 
          headers: { 'authorization':this.tk},
          params: { 
            page: this.$router.currentRoute._rawValue.name,
          },
        },
      ).then(response => {
          if (response.data){
            if (response.data.user){
              if (response.data.user.roles_permissions.layout) {
                this.changeLayoutType({layoutType:response.data.user.roles_permissions.layout})
              }
            }
          }
    });
  },
  mounted() {
    //let self = this
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
      
      eventData: function (eventEl) {
        //console.log(eventEl.innerText)
      //  self.createDraggableNewEvent(eventEl)
        return {
          title: eventEl.innerText,
          start: new Date(),
          className: eventEl.getAttribute("data-class"),
        };
      },
    });
    
    this.apiCalendar = this.$refs.fullCalendar.getApi();
    this.currentData = toRaw(this.apiCalendar.currentData.calendarApi.currentData.dateProfile)
    this.currentRange = toRaw(this.apiCalendar.currentData.calendarApi.currentData.dateProfile.currentRange)
    this.currentRange.start_tmp = moment(this.currentRange.start).unix()
    this.currentRange.end_tmp = moment(this.currentRange.end).unix()
    if (this.currentRange.start_tmp && this.currentRange.end_tmp){
      if (this.type=='user') {
        this.getUserCalendar()
      } 
      if (this.type=='customer') {
        this.getCalendar()
      } 
      if (this.type=='vehicle') {
        this.getVehicleCalendar()
      } 
    }

    if(this.type=='user') {
      this.categories = categories
    } else if(this.type == 'customer') {
      this.categories = customerCategories
    } else {
      this.categories = eventCatByVehicle
    }
    
  },
  watch:{
    apiCalHandleChange: {
       handler: function(newDate, oldDate) {
        this.currentRange.start_tmp = moment(newDate.startStr).unix()
        this.currentRange.end_tmp = moment(newDate.endStr).unix()
        if (!toRaw(oldDate)){
            
            if (this.type=='user') {
              this.getUserCalendar()
            } 
            if (this.type=='customer') {
              this.getCalendar()
            } 
            if (this.type=='vehicle') {
              this.getVehicleCalendar()
            } 
           
            this.changedDataState = true
        } else {
            if ( toRaw(oldDate).startStr!=toRaw(newDate).startStr && toRaw(oldDate).endStr!=toRaw(newDate).endStr ) {
                if (this.changedDataState == false) {
                  if (this.type=='user') {
                    
                    this.calendarOptions.events = []
                    this.getUserCalendar()
                  } 
                  if (this.type=='customer') {
                    
                    this.calendarOptions.events = []
                    this.getCalendar()
                  } 
                  if (this.type=='vehicle') {
                    this.calendarOptions.events = []
                    this.getVehicleCalendar()
                  } 
                }
            }
        }
       },
       once: false ,
    }
  },
  methods: {
    ...mapActions('calendarEvents', ['createNewEvent', 'dragAndDropNewEvent', 'updateEvent',  'getCalendarFilter']),
    ...mapActions('layout', ['changeLayoutType', ]),
    async handleMonthChange(payload) {
      this.apiCalHandleChange = toRaw( payload )
    },
    axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
            
            return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
                Swal.fire({
                  title:"Risorsa non accessibile",
                  text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                this.$router.push('/')   
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
    );      
    },
    formatDateToISO(par,value){
      moment.locale('it')

     if (value){
        if (par=='start'){
          this.event.start = `${value}:00.000Z`

        } else {
          this.event.end = `${value}:00.000Z`
        }
        
      }
     
    },
    formatUnixToISO(value) {
      moment.locale('it')
      if (value) {
        return moment(value, "X").format('YYYY-MM-DDTHH:mm');
      }
    },
    editDate(date_type,date_act) {
      moment.locale('it')
      if (date_act == 'edit') {
        if (date_type=='start') {
            let d = this.edit.start.split('T')
            this.edit.allDay = false
            this.edit.end = this.edit.start 
            this.edit.start_tmp = moment(this.edit.start ).unix()
            this.edit.end_tmp = moment(this.edit.start ).unix()
            
            if (d[1]!='00:00' && this.edit.allDay==true) {
              this.edit.allDay = false
              this.edit.end = this.edit.start 
              this.edit.start_tmp = moment(this.edit.start ).unix()
              this.edit.end_tmp = moment(this.edit.start ).unix()
            } 
        }
        if (date_type=='end') {
          this.edit.start_tmp = moment(this.edit.start ).unix()
          this.edit.end_tmp = moment(this.edit.end ).unix()
            if  (this.edit.start_tmp < this.edit.end_tmp) {
              this.edit.allDay = false
            }
            if (this.edit.start_tmp == this.edit.end_tmp) {
              this.edit.allDay = false
              let current = moment(this.edit.end_tmp, "X").format('YYYY-MM-DDTHH:mm');
             
              let m = moment(current).add(30,'minutes') 
              let mf = moment(m).format('YYYY-MM-DDTHH:mm');
              this.edit.end_tmp = moment( mf).unix() 
              this.edit.end =  mf 
            }
          
        }
        if (date_type=='allDay') {
          this.edit.start_tmp = moment(this.edit.start ).unix()
          let start
          if (this.edit.allDay==true) {
            start = this.edit.start.split('T')
            this.edit.start = start[0]+'T00:00'
            this.edit.end = moment(start[0]+'T00:00').add(1439,'minutes').format('YYYY-MM-DDTHH:mm')
          } else {
            let dateStart = moment().format('YYYY-MM-DDTHH:mm')
            start = dateStart.split('T')
            this.event.start = start[0]+'T00:00'
            this.event.end = moment(start[0]+'T00:00').add(1439,'minutes').format('YYYY-MM-DDTHH:mm')
          }
        }
      }
      if (date_act == 'create') {
        if (date_type=='start') {
           
            let d = this.event.startDate.split('T')
            this.event.start = d[0]+'T00:00'
            this.event.allDay = false
            this.event.end = this.event.startDate 
            this.event.start_tmp = moment(this.event.startDate ).unix()
            this.event.end_tmp = moment(this.event.startDate ).unix()
            
            if (d[1]!='00:00' && this.event.allDay==true) {
              this.event.start = d[0]+'T00:00'
              this.event.allDay = false
              this.event.endDate = this.event.startDate 
              this.event.start_tmp = moment(this.event.startDate ).unix()
              this.event.end_tmp = moment(this.event.startDate ).unix()
            } 
        }
        if (date_type=='end') {
          this.event.start_tmp = moment(this.event.startDate ).unix()
          this.event.end_tmp = moment(this.event.endDate ).unix()
            if  (this.event.start_tmp < this.event.end_tmp) {
              this.event.allDay = false
            }
            if (this.event.start_tmp == this.event.end_tmp) {
              this.event.allDay = false
              let current = moment(this.event.end_tmp, "X").format('YYYY-MM-DDTHH:mm');
             
              let m = moment(current).add(30,'minutes') 
              let mf = moment(m).format('YYYY-MM-DDTHH:mm');
              this.event.end_tmp = moment( mf).unix() 
              this.event.end =  mf 
            }
          
        }
        if (date_type=='allDay') {
          
          this.event.start_tmp = moment(this.event.startDate ).unix()
          if (this.event.allDay==true) {
            let start
            if (this.event.startDate!=null) {
              start = this.event.startDate.split('T')
              this.event.startDate = start[0]+'T00:00'
              this.event.endDate = moment(start[0]+'T00:00').add(1439,'minutes').format('YYYY-MM-DDTHH:mm')
            } else {
              let dateStart = moment().format('YYYY-MM-DDTHH:mm')
              start = dateStart.split('T')
              this.event.startDate = start[0]+'T00:00'
              this.event.endDate = moment(start[0]+'T00:00').add(1439,'minutes').format('YYYY-MM-DDTHH:mm')
            }
            this.event.start_tmp = moment(this.event.startDate ).unix()
            this.event.end_tmp = moment(this.event.endDate ).unix()
          }
          
        }
      }
    },
    formatDate(date) {
      var monthNames = [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ];
      var d = new Date(date),
        month = "" + monthNames[d.getMonth()],
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [day + " " + month, year].join(",");
    },

    getInitialView() {
      if (window.innerWidth >= 768 && window.innerWidth < 1200) {
        return "timeGridWeek";
      } else if (window.innerWidth <= 768) {
        return "listMonth";
      } else {
        return "dayGridMonth";
      }
    },
    dropEvent(arg){

      if (arg) {
        this.calendarOptions.events = []
        let dateStart = moment(arg.dateStr).toISOString()
        let data = {
            title : arg.draggedEl.__vnode.props.key.name,
            extendedProps: { department : arg.draggedEl.__vnode.props.key.name },
            className :  arg.draggedEl.__vnode.props.key.dataClass,
            dataClass:  arg.draggedEl.__vnode.props.key.class,
            category :  arg.draggedEl.__vnode.props.key.category,
            createdBy : this.$store.state.auth.currentUser,
            allDay: arg.allDay,
            start : dateStart,
            end :dateStart, 
            idResource: this.resourceID, 
            start_tmp : moment(dateStart).unix(),
            end_tmp : moment(dateStart).unix(),
            uid_sender : this.$store.state.auth.currentUser.uid,
            uid_recipient : this.$store.state.auth.currentUser.uid,
        } 
        this.dragAndDropNewEvent(data)
        delete arg.jsEvent

        let self = this
        let timerInterval;
        Swal.fire({
          title: "Aggiornamento",
          html: "Registrazione evento in corso...",
          timer: 3000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector("b");
            timerInterval = setInterval(() => {
              timer.textContent = `${Swal.getTimerLeft()}`;
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {

            if (self.type=='customer') {
              self.getCalendar()
            } 
          
          }
        });

        this.getCalendar()
      }
    },
    createDraggableNewEvent(elem){
      
      let data = {
        title : elem.title,
        extendedProps: { department : elem.title },
        className : elem.className,
        category : elem.className,
        createdBy : this.$store.state.auth.currentUser,
        start : elem.start,
        end : elem.start, 
        idResource: this.resourceID, 
        start_tmp : moment(elem.start).unix(),
        end_tmp : moment(elem.start).unix(),
        uid_sender : this.$store.state.auth.currentUser.uid,
        uid_recipient : this.$store.state.auth.currentUser.uid,
      }
     
     this.dragAndDropNewEvent(data)
    },
    getTitle(){
      /*
      className: 'bg-soft-primary',
      location: 'San Francisco, US',
      allDay: false,
      extendedProps: {
        department: 'Nuova Installazione '
      },

      this.event = {
        "extendedProps":{
          "department":  this.event.title
        }
      }
      */
     // this.event.extendedProps.department = this.event.title
      console.log(this.event)

    },
    getCat(){
      /*
        className: 'bg-soft-primary',
        location: 'San Francisco, US',
        allDay: false,
        extendedProps: {
          department: 'Nuova Installazione '
        },
      */
      this.event.className = this.event.category
      let self = this
      let optionsCategories = this.categories.filter(function(el){
        return el.category == self.event.category
      })
      if (optionsCategories)
      {
        self.event.name =  optionsCategories[0].name
        self.event.category =  optionsCategories[0].category
        self.event.event_type =  optionsCategories[0].event_type
        self.event.value =  optionsCategories[0].value
        self.event.className =  optionsCategories[0].value
        self.event.dataClass =  optionsCategories[0].dataClass
      }

    },
    filterEditCat(cat){
      let self = this
      let optionsCategories = this.categories.filter(function(el){
        return el.category == cat
      })
      if (optionsCategories)
      {
        self.edit.name =  optionsCategories[0].name
        self.edit.category =  cat
        self.edit.event_type =  optionsCategories[0].event_type
        self.edit.value =  optionsCategories[0].value
        self.edit.className =  optionsCategories[0].className
        self.edit.dataClass =  optionsCategories[0].class
      }

    },
    getFilterEventBy(){
      let self = this
      this.filterEventBy
      let data  = this.calendarOptions.events.filter(function(el) {
        return el.category == self.filterEventBy
      })
      
      this.calendarOptions.events = data[0]
      if (this.type=='user') {
        this.getUserCalendar()
      } 
      if (this.type=='customer') {
          this.getCalendar()
        } 
      if (this.type=='vehicle') {
        this.getVehicleCalendar()
      } 

    },
    cancelFilter(){
      this.filterEventBy=''
      if (this.type=='user') {
        this.getUserCalendar()
      } 
      if (this.type=='customer') {
          this.getCalendar()
        } 
      if (this.type=='vehicle') {
        this.getVehicleCalendar()
      } 
    },
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;
      this.v$.$touch()

        this.event.createdBy = this.$store.state.auth.currentUser
        this.event.start_tmp = moment(this.event.start).unix()
        this.event.end_tmp = moment(this.event.end).unix()
       
        this.event.uid_sender = this.$store.state.auth.currentUser.uid
        this.event.uid_recipient = this.$store.state.auth.currentUser.uid
        this.event.classNames = this.category
        this.event.module = this.module 
        this.event.idResource = this.resourceID
       
        this.createNewEvent(this.event)
        //this.successmsg();
        this.showModal = false;
        this.newEventData = {};
        if (this.type=='user') {
          this.getUserCalendar()
        } 
        if (this.type=='customer') {
          this.getCalendar()
        } 
        if (this.type=='vehicle') {
          this.getVehicleCalendar()
        } 
       
     
      this.submitted = false;
      this.event = {};
    },
    getSelectDateCalendar(id){
      this.axiosInterceptor()
      axios.get(`${serverConfig.EP}events/${id}`, 
          { 
            headers: { 'authorization':this.tk}}
       ).then(response=>{
        this.edit = response.data
        this.edit.start = this.formatUnixToISO(response.data.start_tmp)
        this.edit.end = this.formatUnixToISO(response.data.end_tmp)
      })
    },
    getCalendar(){
      this.axiosInterceptor()
      axios.get(`${serverConfig.EP}events/${this.type}/calendar`, 
          { 
            params: { 
              customerID: this.resourceID,
              vehicleID: this.resourceID,
              filterBy: this.filterEventBy,
              start: this.currentRange.start,
              end: this.currentRange.end,
              start_tmp: this.currentRange.start_tmp,
              end_tmp: this.currentRange.end_tmp,
            }, 
            headers: { 'authorization':this.tk}}
       ).then(response=>{
          this.calendarOptions.events = []
          this.calendarOptions.events = response.data.events;
          this.genEventsOptionsList(response.data.events)
          this.getCalendarTasks()
          this.changedDataState == false
      })
    },
    getCalendarTasks(){
      this.axiosInterceptor()
      axios.get(`${serverConfig.EP}events/${this.type}/calendar-tasks`, 
          { 
            params: { 
              customerID: this.resourceID,
              vehicleID: this.resourceID,
              start: this.currentRange.start,
              end: this.currentRange.end,
              start_tmp: this.currentRange.start_tmp,
              end_tmp: this.currentRange.end_tmp,
            }, 
            headers: { 'authorization':this.tk}}
       ).then(response=>{
         
          if (response.data) {
            if (response.data.events.length>0) {
              for (let item of response.data.events){
                if (this.eventsOptionsList.includes(item._id)==false ) {
                    this.calendarOptions.events.push({
                    type:'task',
                    taskID: item._id,
                    projectID: item.projectID,
                    worksiteID: item.worksiteID,
                    title: item.name,
                    start: item.startStr,
                    end: item.endStr,
                    allDay: item.name,
                    category:"bg-soft-primary",
                    className : "bg-soft-primary",
                    extendedProps:{
                      department: "Attività richiesta"
                    },
                  
                    timed:false,
                  })
                }

              }
            }
          }
          this.calendarTasks = response.data
        })
    },
    getUserCalendar(){
      this.axiosInterceptor()
      axios.get(`${serverConfig.EP}events/${this.type}/calendar`, 
          { 
            params: { 
              filterBy: this.filterEventBy,
              start: this.currentRange.start,
              end: this.currentRange.end,
              start_tmp: this.currentRange.start_tmp,
              end_tmp: this.currentRange.end_tmp,
            }, 
            headers: { 'authorization':this.tk}}
       ).then(response=>{
          this.calendarOptions.events = []
          this.calendarOptions.events = response.data.events;
          this.genEventsOptionsList(response.data.events)
          this.getUserCalendarTasks()
          this.changedDataState == false
      })
    },
    getUserCalendarTasks(){
      this.axiosInterceptor()
      axios.get(`${serverConfig.EP}events/user/calendar-tasks`, 
          { 
            params: { 
              start: this.currentRange.start,
              end: this.currentRange.end,
              start_tmp: this.currentRange.start_tmp,
              end_tmp: this.currentRange.end_tmp,
            }, 
            headers: { 'authorization':this.tk}}
       ).then(response=>{
         
          if (response.data) {
            if (response.data.events.length>0) {
              for (let item of response.data.events){
                if (this.eventsOptionsList.includes(item._id)==false ) {
                    this.calendarOptions.events.push({
                    type:'task',
                    taskID: item._id,
                    projectID: item.projectID,
                    worksiteID: item.worksiteID,
                    title: item.name,
                    start: item.startStr,
                    end: item.endStr,
                    allDay: item.name,
                    category:"bg-soft-primary",
                    className : "bg-soft-primary",
                    extendedProps:{
                      department: "Attività richiesta"
                    },
                  
                    timed:false,
                  })
                }

              }
            }
          }
          this.calendarTasks = response.data
        })
    },
    getVehicleCalendar(){
      this.axiosInterceptor()
      axios.get(`${serverConfig.EP}events/vehicle/calendar`, 
          { 
            params: { 
              vehicleID: this.resourceID,
              filterBy: this.filterEventBy,
              requestType: this.type,
              start: this.currentRange.start,
              end: this.currentRange.end,
              start_tmp: this.currentRange.start_tmp,
              end_tmp: this.currentRange.end_tmp,
            }, 
            headers: { 'authorization':this.tk}}
       ).then(response=>{
          this.calendarOptions.events = []
          this.calendarOptions.events = response.data.events;
          this.genEventsOptionsList(response.data.events)
          this.getVehicleCalendarTasks()
          this.changedDataState == false
      })
    },
    getVehicleCalendarTasks(){
      this.axiosInterceptor()
      axios.get(`${serverConfig.EP}events/vehicle/calendar-tasks`, 
          { 
            params: { 
              vehicleID: this.resourceID,
              start: this.currentRange.start,
              end: this.currentRange.end,
              start_tmp: this.currentRange.start_tmp,
              end_tmp: this.currentRange.end_tmp,
            }, 
            headers: { 'authorization':this.tk}}
       ).then(response=>{
          
          if (response.data) {
            if (response.data.events.length>0) {
             
              for (let item of response.data.events){
                
                if (this.eventsOptionsList.includes(item._id)==false ) { 
                  if (item.task.length>0){
                    this.calendarOptions.events.push({
                      type: item.task[0].task_type,
                      taskID: item.task[0]._id,
                      projectID: item.task[0].projectID,
                      worksiteID: item.task[0].worksiteID,
                      title: item.task[0].name,
                      start: item.task[0].startStr,
                      end: item.task[0].endStr,
                      allDay: item.task[0].name,
                      name: item.task[0].name,
                      category:'impegnato',
                      event_type:'impegnato',
                      value: 'bg-success',
                      dataClass: 'bg-soft-success',
                    
                      class: 'external-event fc-event bg-soft-success text-success',
                      className : "bg-soft-success",
                    
                      extendedProps:{
                        department: 'Mezzo impegnato'
                      },
                    
                      timed:false,
                    })
                  }

                }

              }
              
            }
          }
          this.calendarTasks = response.data
        })
    },
    genEventsOptionsList(array){
      
      if(array.length>0) {
        let self = this
        for (let item of array) {
          self.eventsOptionsList.push(item._id)
        }
      }
    },
    deleteItem(id){
      this.axiosInterceptor()
      axios.delete(`${serverConfig.EP}events/${id}`, 
          { 
            headers: { 'authorization':this.tk}
          }
       ).then(()=>{
        this.eventModal = false;
        this.getCalendar()
        Swal.fire({
          title:"Evento rimosso",
          text:"L'evento è stato rimosso",
          icon:"success",
          showCancelButton:false,
          showConfirmButton:false,
          timer:3000,
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showModal = false;
      this.event = {};
    },
    /**
     * Edit event modal submit
     */
    // eslint-disable-next-line no-unused-vars
    editSubmit(e) {
      this.submit = true;
      this.v$.$touch()
      
      this.edit.start_tmp = moment(this.edit.start).unix()
      this.edit.end_tmp = moment(this.edit.end).unix()

      this.updateEvent({id:this.edit._id, body: this.edit})
      this.eventModal = false;
    },

    /**
     * Delete event
     */
    deleteEvent() {
      //this.edit.remove();
      this.eventModal = false;
    },
    /**
     * Modal open for add event
     */
    dateClicked(info) {
      this.newEventData = info;
      this.showModal = true;
    },
    /**
     * Modal open for edit event
     */
    editEvent(info) {
      this.edit = {}
      if (info.event.extendedProps) {
       
        if (info.event.extendedProps._id!=undefined)
        {
          this.editableData = true
       
          this.edit = toRaw(info.event.extendedProps);
         
          this.getSelectDateCalendar(this.edit._id)
          
        } else {
          this.editableData = false
          
        }
      } 

/*
      this.edit = info.event;
      this.edit.start_tmp2 = moment(this.edit.start).format("MM/DD/YYYY")
      this.edit.startDate = moment(this.edit.start).format("MM/DD/YYYY")

      this.editevent.editTitle = this.edit.title;
      this.editevent.editcategory = this.edit.classNames[0];

      this.editevent.updatedBy = this.$store.state.auth.currentUser
      this.editevent.start_tmp = moment(this.edit.start).unix()
      this.editevent.end_tmp = moment(this.edit.end).unix()
      this.editevent.start_tmp2 = moment(this.edit.start).format("MM/DD/YYYY")

      this.editevent.uid_sender = this.$store.state.auth.currentUser.uid
      this.editevent.uid_recipient = this.$store.state.auth.currentUser.uid
     
      if (this.edit.classNames){
        if (this.edit.classNames.length>0){
          this.editevent.classNames = this.edit.classNames[0]
        }
      }
      */

        //this.event.id_sender = this.$store.auth.currentUser.uid
        /*
        this.currentEvents = calendarApi.addEvent({
          id: this.newEventData.length + 1,
          title,
          //start: this.newEventData.date,
          start:this.event.start,
          end:this.event.end,
          //end: this.newEventData.date,
          classNames: [category],
        });
        */
      //  this.createNewEvent(this.event)


      this.eventModal = true;
    },

    closeModal() {
      this.eventModal = false;
    },

    confirm(id) {
      Swal.fire({
        title: "Vuoi eliminare il contenuto?",
        text: "La rimozione di questo dato sarà definitiva. Vuoi Proseguire o Annullare l'evento?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annulla",

        confirmButtonText: "Sì, cancellalo!",
      }).then((result) => {
        if (result.value) {
          this.deleteItem(id);
          
        }
      });
    },

    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },

    /**
     * Show successfull Save Dialog
     */
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Event has been saved",
        showConfirmButton: false,
        timer: 1000,
      });
    },
  },
};
</script>

<template>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-xl-3">
            <div class="card card-h-100">
              <div class="card card-h-100">
                <div class="card-body">
                  <h5 class="mb-1"> Filtra risultati</h5>
                  <select 
                    class="form-select" 
                    required 
                    aria-label="Categoria evento" 
                    v-model="filterEventBy" 
                    @change="getFilterEventBy">
                        <option
                          v-for="option in categories"
                          :key="option.backgroundColor"
                          :value="`${option.category}`"
                        >
                        {{ option.name }}
                    </option>
                  </select>
                  <template v-if="filterEventBy!=''">
                    <a  href="javascript:void(0)" @click="cancelFilter">Annulla filtro</a>
                  </template>
                 
                </div>
              </div>
              <div class="card-body">
                <button class="btn btn-primary w-100" id="btn-new-event" @click="showModal = true">
                  <i class="mdi mdi-plus"></i> Crea nuovo evento 
                </button>

                <div id="external-events">
                  <br />
                  <p class="text-muted">
                    {{ $t("t-drag-and-drop-event") }}
                  </p>
                  <div
                    v-for="item in categories"
                    :class="item.class"
                    :data-class="item.dataClass"
                    :key="item"
                  >
                    <i
                      class="mdi mdi-checkbox-blank-circle font-size-11 me-2"
                    ></i> 
                      {{item.name}}
                  </div>
                </div>
              </div>


            </div>
            <div>
              <h5 class="mb-1"> {{ $t("t-upcoming-events") }}</h5>
              <p class="text-muted"> {{ $t("t-dont-miss-events") }}</p>
              <SimpleBar
                class="upcoming-events pe-2 me-n1 mb-3"
                data-simplebar="init"
                style="height: 400px"
              >
                <div
                  class="card mb-3"
                  v-for="event in currentEvents"
                  :key="event.id"
                >
                  <div class="card-body">
                    <div class="d-flex mb-3">
                      <div class="flex-grow-1">
                        <i
                          :class="`mdi mdi-checkbox-blank-circle me-2 ${event.classNames[0]} `"
                        ></i
                        ><span class="fw-medium">{{
                          this.formatDate(event.start)
                        }}</span>
                      </div>
                      <div class="flex-shrink-0">
                        <small class="badge badge-soft-primary ms-auto"></small>
                      </div>
                    </div>
                    <h6 class="card-title fs-16">{{ event.title }}</h6>
                    <p class="text-muted text-truncate-two-lines mb-0">
                      {{ event.description }}
                    </p>
                  </div>
                </div>
              </SimpleBar>
            </div>
            <div class="card shadow-none">
              <div class="card-body bg-soft-info rounded">
                <div class="d-flex">
                  <div class="flex-shrink-0">
                    <CalendarIcon
                      class="text-info icon-dual-info"
                    ></CalendarIcon>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h6 class="fs-15">{{ $t("t-welcome-to-your-calendar") }}</h6>
                    <p class="text-muted mb-0">
                      {{ $t("t-welcome-calendar-description") }}
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <!--end card-->
          </div>
          <div class="col-xl-9">
            <div class="card card-h-100">
              <div class="card-body">
                <FullCalendar ref="fullCalendar" :options="calendarOptions" >
                </FullCalendar>
              </div>
            </div>
          </div>
        </div>
        <div style="clear: both">

        </div>
      </div>
    </div>
    <b-modal
      v-model="showModal"
      title="Aggiungi nuovo evento"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
      class="v-modal-custom"
      static=true
    >
      <form class="needs-validation" @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Nome evento</label>
              <input
                id="name"
                v-model="event.title"
                type="text"
                class="form-control"
                placeholder="Inserisci nome evento"
              
                :class="{ 'is-invalid': submitted && v$.event.title.$error }"
                required
              />
              <div
                v-if="submitted && v$.event.title.$error"
                class="invalid-feedback"
              >
                <span v-if="v$.event.title.required.$message">{{
                  v$.event.title.required.$message
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <div class="form-check form-switch">
                 <label for="name">Tutto il giorno</label>
                <input class="form-check-input" type="checkbox" role="switch"  v-model="event.allDay"  id="createAllDay" @blur="editDate('allDay','create')" >
                
              </div>            
            </div>
          </div>
  
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Inizia</label>
              <input
                id="start"
                v-model="event.startDate"
                type="datetime-local"
                class="form-control"
                placeholder="Inserisci data inizio evento"
                @blur="editDate('start','create')"
                :class="{
                  'is-invalid': submitted && v$.event.startDate.$error,
                }"  
                required 
              />
              <div v-for="(item, index) in v$.event.startDate.$errors" :key="index" class="invalid-feedback">
                <span v-if="item.$message">{{ item.$message }}</span>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Finisce</label>
              <input
                id="end"
                v-model="event.endDate"
                type="datetime-local"
                class="form-control"
                placeholder="Inserisci data fine evento"
                :min="event.startDate"
                @blur="editDate('end','create')"
                :class="{
                  'is-invalid': submitted && v$.event.endDate.$error,
                }"  
                required 
              />
              <div v-for="(item, index) in v$.event.endDate.$errors" :key="index" class="invalid-feedback">
                <span v-if="item.$message">{{ item.$message }}</span>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Localizzazione</label>
              <input
                id="location"
                v-model="event.location"
                type="text"
                class="form-control"
                placeholder="Localizzazione evento"
              
              />

            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label class="control-label">Categoria</label>
                <select 
                  class="form-select" 
                  required 
                  aria-label="Categoria evento" 
                  v-model="event.category" 
                  :class="{
                  'is-invalid': submitted && v$.event.category.$error,}" @change="getCat">
                    <option
                      v-for="option in categories"
                      :key="option.backgroundColor"
                      :value="`${option.category}`"
                    >
                      {{ option.name }}
                  </option>
                </select>
                <div v-for="(item, index) in v$.event.category.$errors" :key="index" class="invalid-feedback">
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
        
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Note</label>
              
              <textarea
                id="end"
                v-model="event.description"
                type="text"
                class="form-control"
                placeholder="Inserisci note evento"
              ></textarea>

            </div>
          </div>
        </div>
        <div class="text-end pt-5 mt-3">
          <b-button variant="light" @click="hideModal">Chiudi</b-button>
          <b-button type="submit" variant="success" class="ms-1"
            >Crea evento</b-button
          >
        </div>
      </form>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      v-model="eventModal"
      title="Modifica Evento"
      title-class="text-black font-18"
      hide-footer
      body-class="p-3"
      class="v-modal-custom"
    >
      <form class="needs-validation" @submit.prevent="editSubmit">
        <div class="row" v-if="editableData==true">
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Nome evento</label>
              <input
                id="editname"
                v-model="edit.title"
                type="text"
                class="form-control"
                placeholder="Inserisci nome evento"
                
                :class="{ 'is-invalid': submitted && v$.edit.title.$error }"
              />
              <div
                v-if="submitted && v$.edit.title.$error"
                class="invalid-feedback"
              >
                <span v-if="v$.edit.title.required.$message">{{
                  v$.edit.title.required.$message
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <div class="form-check form-switch">
                 <label for="name">Tutto il giorno</label>
                <input class="form-check-input" type="checkbox" role="switch"  v-model="edit.allDay"   @blur="editDate('allDay','edit')" id="editflexSwitchCheckCheckedDisabled" >
                
              </div>            
            </div>
          </div>
  
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Inizia</label>
              <input
                id="editstart"
                v-model="edit.start"
                type="datetime-local"
                class="form-control"
                placeholder="Inserisci data inizio evento"
                @blur="editDate('start','edit')"
                :class="{
                  'is-invalid': submitted && v$.edit.start.$error,
                }"  
                required 
              />
              <div v-for="(item, index) in v$.edit.start.$errors" :key="index" class="invalid-feedback">
                <span v-if="item.$message">{{ item.$message }}</span>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Finisce</label>
              <input
                id="end"
                v-model="edit.end"
                type="datetime-local"
                class="form-control"
                placeholder="Inserisci data fine evento"
                :min="edit.start"
                @blur="editDate('end','edit')"
                :class="{
                  'is-invalid': submitted && v$.edit.end.$error,
                }"  
                required 
              />
              <div v-for="(item, index) in v$.edit.end.$errors" :key="index" class="invalid-feedback">
                <span v-if="item.$message">{{ item.$message }}</span>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Localizzazione</label>
                <input
                  id="location"
                  v-model="edit.location"
                  type="text"
                  class="form-control"
                  placeholder="Localizzazione evento"
                />
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label class="control-label">Categoria</label>
                <select 
                  class="form-select" 
                  required 
                  aria-label="Categoria evento" 
                  v-model="edit.category" 
                  :class="{
                  'is-invalid': submitted && v$.edit.category.$error,}" @change="filterEditCat(edit.category)">
                    <option
                      v-for="option in categories"
                      :key="option.backgroundColor"
                      :value="`${option.category}`"
                    >
                      {{ option.name }}
                  </option>
                </select>
                <div v-for="(item, index) in v$.edit.category.$errors" :key="index" class="invalid-feedback">
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="editableData==true">
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Note</label>
              
              <textarea
                id="end"
                v-model="edit.description"
                type="text"
                class="form-control"
                placeholder="Inserisci note evento"
              ></textarea>

            </div>
           
          </div>
        </div>
        <div class="row" v-if="editableData==false">
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Nome evento</label>
              {{ edit.title }}
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <div class="form-check form-switch">
                 <label for="name">Tutto il giorno</label>
                <input class="form-check-input" type="checkbox" role="switch"  v-model="edit.allDay" readonly="readonly" >
                
              </div>            
            </div>
          </div>
  
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Inizia</label>
              {{ edit.startDate }}
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Finisce</label>
              {{ edit.endDate }}
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Localizzazione</label>
              {{edit.location}}
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label class="control-label">Categoria</label>
                <p  v-for="option in categories"
                  :key="option.backgroundColor"
                  :value="`${option.category}`">
                  <template v-if="option.value==edit.category">
                    {{ option.name }}
                  </template>
                </p>        
            </div>
          </div>
        </div>
        <div class="text-end p-3" v-if="editableData==true">
          <b-button variant="light" @click="closeModal">Annulla</b-button>
          <b-button class="ms-1" variant="danger" @click="confirm(edit._id)"
            >Rimuovi</b-button
          >
          <b-button class="ms-1" variant="success" @click="editSubmit"
            >Salva</b-button
          >
        </div>
      </form>
    </b-modal>
 
</template>
